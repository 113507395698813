<template>
  <div>
    <add-customer></add-customer>
  </div>
</template>

<script>
import AddCustomer from './AddClient.vue';

export default {
  components: {
    AddCustomer,
  },

  data() {
    return {};
  },
};
</script>
